import Homepage from "./Homepage/Homepage-js"
import { Route, Routes } from "react-router-dom"
import Navbar from "./Navbar/Navbar-js"
import About from "./About/About-js"
import Contact from "./Contact/Contact-js"
import Service from "./Service/Service-js"

function Links() {
  return (
    <Routes>
      {/* Homepage */}
      <Route path="/" element={<Homepage />} />

      {/* About + Contact + Service page */}
      <Route
        path="/about"
        element={
          <>
            <Navbar />
            <About />
          </>
        }
      />
      <Route
        path="/contact"
        element={
          <>
            <Navbar />
            <Contact />
          </>
        }
      />
      <Route
        path="/service"
        element={
          <>
            <Navbar />
            <Service />
          </>
        }
      />
    </Routes>
  )
}

export default Links
