import './Homepage-css.css';
import HomepageNavbar from './Homepage-navbar';

function Homepage() {
  return (
    <div>
      <div className="Homepage-container">
        <HomepageNavbar/>
      </div>

      <div className = "Homepage-second-container">
        <div className = "Homepage-first-subcontainer"></div>
        <div className = "Homepage-second-subcontainer">
          <p><a className = "Homepage-highlight">T </a> 07432694421</p>
          <p><a className = "Homepage-highlight">E </a> enquiry-2ca@hotmail.com</p>
        </div>
        <div className = "Homepage-third-subcontainer">
          <p><a className = "Homepage-highlight">© </a> 2023 By 2CArchitectural designers </p>
        </div>
      </div>
    </div>
  );
}

export default Homepage;
