import './Service-css.css';

function Service() {
  return (
    <div className="Service-container">
      <div class="Service-inner-container">
              <div class="service">
                  <p className='service-title'>Planning Application Drawings and Forms</p>
                  <p className = "service-text">Our team can assist you in preparing detailed planning application drawings and forms to streamline the approval process for your architectural projects.</p>
                  <p className = "service-price">~ Starting from £500</p>
              </div>
              <div class="service">
                  <p className='service-title'>Pre-Application</p>
                  <p className = "service-text">We provide pre-application services to help you assess the feasibility of your projects, identify potential challenges, and refine your design plans.</p>
              </div>
              <div class="service">
                  <p className='service-title'>HMO</p>
                  <p className = "service-text">We offer professional services for HMO (House in Multiple Occupation) projects to ensure compliance with local regulations and safety standards.</p>
                  <p className = "service-price">~ Starting from £300</p>
              </div>
              <div class="service">
                  <p className='service-title'>Advice and Design</p>
                  <p className = "service-text">Our experienced architecural client offer expert advice and collaborate with you to create innovative and functional designs for your projects.</p>
              </div>
              <div class="service">
                  <p className='service-title'>Partnership with a Team of Builders, Electricians, Plumbers, Contractors</p>
                  <p className = "service-text">We work in partnership with a network of skilled professionals, including builders, electricians, plumbers, and contractors, to ensure a comprehensive and successful project execution.</p>
              </div>
              <div class="service">
                  <p className='service-title'>High-End Visualisers</p>
                  <p className = "service-text">Our high-end visualisation services bring your architectural concepts to life, allowing you to explore and showcase your designs with stunning visual representations.</p>
              </div>
          </div>

          <div className = "service-footer-second-container">
            <div className = "service-footer-first-subcontainer"></div>
            <div className = "service-footer-second-subcontainer">
              <p><a className = "service-footer-highlight">T </a> 07432694421</p>
              <p><a className = "service-footer-highlight">E </a> enquiry-2ca@hotmail.com</p>
            </div>
            <div className = "service-footer-third-subcontainer">
              <p><a className = "service-footer-highlight">© </a> 2023 By 2CArchitects&Associates</p>
            </div>
          </div>
    </div>
  );
}

export default Service;
