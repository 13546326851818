import './About-css.css';
import CustomLink from "../misc-components/CustomLink";
import ChangingText from '../Homepage/Changing-text';
import Popupbox from './Modal';

function About() {
  return (
    <div className="About-container">    
      <div className="About-second-container">
        <div className = "About-large-image">
          <p className = "About-large-image-text"><ChangingText/></p>
        </div>
        <div className="About-second-inner-container">
          <p className = "About-second-right-text-title">
          Welcome to 2CA Architectural Studio, 
          </p>
          <p className = "About-second-right-text">
            Where youth meets expertise in the realm of design. 
            As newly found firm with six years of specialized experience in high end residential design, 
            we've founded this firm to infuse creativity into every space we craft. Our competitive pricing model ensures that exceptional design is accessible without compromise. 
            Starting this journey fuelled by passion, we've cultivated a portfolio that reflects our commitment to blending innovation with functionality. 
            Let us transform your vision into reality, as we bring fresh perspectives and a dedication to excellence to every project we undertake.
          </p>
          <p className = "About-second-right-text">
          <CustomLink to = "/service" text = "What we can do for you" />
          </p>
        </div>
      </div>
      <div className="About-third-container">
        <div className = "About-third-inner-left-container">
          <div className = "About-third-large-image"></div>
        </div>
        <div className = "About-third-inner-right-container">
          <p className = "About-third-right-title">
          Pre-app and Planning Application
          </p>
          <p className= "About-third-right-text-2">
            At 2CArchitectural designers & associates, we offer a streamlined approach to planning applications, and we go beyond that. Our process is simple yet comprehensive, 
            comprising of three key steps: advice, drawings, and application submission.
          </p>
          <p className = "About-third-right-title">Our approach</p>
          <p className= "About-third-right-text-2">
            <span className = "About-third-right-title">Expert Advice: </span> We begin by providing expert advice to understand your specific needs and project goals. Our team is here to guide you through the 
            entire planning application process, ensuring you have a clear understanding of what to expect.
          </p>
          <p className= "About-third-right-text-2">
            <span className = "About-third-right-title">Planning Drawings: </span> Our experienced architectural designers and designers create detailed, professional drawings that encompass all the 
            necessary information for your application. We focus on precision, including dimensions and compliance with regulations.
          </p>
          <p className= "About-third-right-text-2">
            <span className = "About-third-right-title">Efficient Application Submission: </span>We make the application submission process hassle-free. Our team takes care of the paperwork, 
            ensuring your application is submitted accurately and on time.
          </p>
          <p className= "About-third-right-text-2">
            But that's not all. What sets us apart is our extensive team of professionals who can produce high-end visualizations and drawings at a rapid pace. 
            Our visualizations bring your ideas to life, ensuring you can clearly envision your project's potential. It's important to note that visualizations 
            are an option and not a requirement, but they can greatly enhance your project planning and presentation.
          </p>
          <p className= "About-third-right-text-2">
            Moreover, we have a dedicated contractor team that can seamlessly carry out projects on-site, turning your plans into reality. 
            We offer a complete package, from concept to construction, ensuring your project is executed to perfection.
          </p>
          <p className= "About-third-right-text">
            <CustomLink text = {"View our services"} to = {"/service"}/>
          </p>
        </div>
      </div>
      <div className="About-forth-container">
        <div className = "About-forth-inner-left-container">
          <p className = "About-forth-right-title">
            House in multiple occupation (HMO)
          </p>
          <p className= "About-forth-right-text">
            At 2CArchitectural designers & associates, our approach is straightforward: we simplify the complex process of HMO planning. We 
            begin by closely understanding our clients' needs, conducting comprehensive building surveys, and creating precise plans that include all necessary 
            details, such as dimensions and fire regulations.
          </p>
          <p className= "About-forth-right-text">HMO applications can be intricate, but we make it easy. Our team at 2CArchitectural designers is committed to keeping the process simple while delivering top-notch results.</p>
          <p className= "About-forth-right-text">
          <Popupbox/>
          </p>
        </div>
        <div className = "About-forth-inner-right-container">
          <div className = "About-forth-large-image"></div>
        </div>
      </div>

      <div className = "about-footer-second-container">
        <div className = "about-footer-first-subcontainer"></div>
        <div className = "about-footer-second-subcontainer">
          <p><a className = "about-footer-highlight">T </a> 07432694421</p>
          <p><a className = "about-footer-highlight">E </a> enquiry-2ca@hotmail.com</p>
        </div>
        <div className = "about-footer-third-subcontainer">
          <p><a className = "about-footer-highlight">© </a> 2023 By 2CArchitectural Designers</p>
        </div>
      </div>

    </div>
  );
}

export default About;
