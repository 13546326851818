import './Homepage-navbar.css';
import { Link } from 'react-router-dom';

function HomepageNavbar() {
  return (
    <div className="Homepage-Navbar-main-container">
      <div className = "Homepage-Navbar-left">
        <div className = "Homepage-Navbar-inner-left">
            <Link className = "Homepage-Navbar-item" to = "/"> 
              
              <span>2CArchitectural<span className = "Associates">  Designers </span></span>
            </Link>
        </div>
      </div>
      <div className = "Homepage-Navbar-right">
        <div className = "Homepage-Navbar-inner-right"> 
            <Link className = "Homepage-Navbar-item Navbar-item-hover" to = "/about"> about </Link>
            <Link className = "Homepage-Navbar-item Navbar-item-hover" to = "/contact"> contact </Link>
            <Link className = "Homepage-Navbar-item Navbar-item-hover" to = "/service"> services </Link>
        </div>
      </div>
    </div>
  );
}

export default HomepageNavbar;
