import { Link } from 'react-router-dom';
import './CustomLink-css.css';

function ServiceLink(props) {
  return (
    <div className="service-link-container">
      <div>
        <Link className = "service-link-text" to = {props.to}>{props.text}</Link>
      </div>
    </div>
  );
}

export default ServiceLink;
