import './Contact-css.css';
import './Contact-input.css';

function Contact() {
  return (
    <div className="Contact-container">
      <div className="Contact-inner-container-left">
      <p className="Contact-left-title">Want to get in touch?</p>
        <form
          action="https://formspree.io/f/xzblkzbq"
          method="POST"
        >
          <div class="Contact-input-container">
            <input type="text" name = "email" id="input" required/>
            <label for="input" class="Contact-label">Email</label>
            <div class="underline"></div>
          </div>
          <div>
            <textarea placeholder = "Enter your message here" className = "Contact-textarea" required name = "message"></textarea>
          </div>
          <button type="submit" className = "Contact-submit-container">
            <span className = "Contact-submit-element">Send</span>
          </button>
        </form>
      </div>
      <div className="Contact-inner-container-right">
        <div className="Contact-inner-inner-container-right">
        <p>If you wish to contact us for a <span className = "Contact-highlight-2">free quote</span>, refer to the details below. 
        Alternatively simply fill out the form, and we'll get in touch with you.</p>
        <p><a className = "Contact-highlight">T </a> 07432694421</p>
        <p><a className = "Contact-highlight">E </a> enquiry-2ca@hotmail.com</p>
        </div>
      </div>
    </div>
  );
}

export default Contact;
