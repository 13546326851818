import React, { useEffect, useState } from 'react';
import './Changing-text-css.css';

function ChangingText() {
  const [textIndex, setTextIndex] = useState(0);
  const dataText = ["Clean", "Artistry", "Professional", "Craftsmanship"];

  useEffect(() => {
    let isUnmounted = false;

    function typeWriter(text, i, fnCallback) {
      if (i < text.length && !isUnmounted) {
        document.querySelector("p").innerHTML = text.substring(0, i + 1) + '<span aria-hidden="true"></span>';
        setTimeout(function () {
          typeWriter(text, i + 1, fnCallback);
        }, 50);
      } else if (typeof fnCallback === 'function' && !isUnmounted) {
        setTimeout(fnCallback, 700);
      }
    }

    function startTextAnimation() {
      if (textIndex < dataText.length) {
        typeWriter(dataText[textIndex], 0, function () {
          if (!isUnmounted) {
            setTimeout(() => {
              setTextIndex(textIndex + 1);
            }, 1000);
          }
        });
      } else {
        setTimeout(() => {
          if (!isUnmounted) {
            setTextIndex(0);
          }
        }, 2000);
      }
    }

    startTextAnimation();

    // Cleanup function
    return () => {
      isUnmounted = true;
    };
  }, [textIndex]);

  return (
    <div>
      <p className="Changing-text">.</p>
    </div>
  );
}

export default ChangingText;
