import './Navbar-css.css';
import { Link } from 'react-router-dom';
import Logo from '../Images/2CALogoGold.png'

function Navbar() {
  return (
    <div className="Navbar-main-container">
      <div className = "Navbar-left">
        <div className = "Navbar-inner-left">
          <Link className = "Navbar-item" to = "/"> 2CArchitectural<span className = "Associates"> Designers</span> </Link>
        </div>
      </div>
      <div className = "Navbar-middle">
        <Link to = "/"><img className = "Logo" src = {Logo} /></Link>
      </div>
      <div className = "Navbar-right">
        <div className = "Navbar-inner-right"> 
        <Link className = "Navbar-item Navbar-item-hover" to = "/about"> about </Link>
          <Link className = "Navbar-item Navbar-item-hover" to = "/contact"> contact </Link>
          <Link className = "Navbar-item Navbar-item-hover" to = "/service"> services </Link>
          <hr className ="Hr-style"></hr>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
